import data2019 from "./2019/result.json";
import CONTEST2019 from "./2019/contests.json";

export const load2019 = () => {
  return data2019;
};

export const contest2019 = () => {
  return CONTEST2019;
};
